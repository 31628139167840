import cx from 'clsx'
import { Button } from '@/components/atoms/button'
import SafeLink from '@ignition/library/components/atoms/safe-link'
import Text from '@ignition/library/components/atoms/text'
import Arrow from '../../svg/arrow.svg'
import ImageElement from './image'

const styles = {
  hero: 'relative bg-gray-900 lg:overflow-hidden lg:pt-8 bg-cover bg-bottom md:min-h-[25vh] h-full aspect-ratio',
  heroContainer: 'relative flex justify-center items-center mx-auto max-w-3xl lg:px-8 h-full',
  heroContent: 'px-4 sm:px-6 sm:text-center lg:items-center lg:px-0 lg:text-left',
  label:
    'hidden sm:inline-flex text-white items-center rounded-full bg-primary p-1 px-4 hover:text-gray-200 sm:text-base lg:text-sm xl:text-base mb-4',
  mainTitles: 'font-bold tracking-tight text-white sm:mt-5 lg:mt-6',
  title: 'block text-2xl sm:text-4xl lg:text-5xl',
  subtitle: 'block pb-3 sm:pb-5 text-xl',
}

const customAspectRatio = (aspectRatio: string) => {
  switch (aspectRatio) {
    case '1:1':
      return 'lg:aspect-square'
    case '2:1':
      return 'lg:aspect-[2/1]'
    case '3:1':
      return 'lg:aspect-[3/1]'
    case '4:6':
      return 'lg:aspect-[4/6]'
    case '5:7':
      return 'lg:aspect-[5/7]'
    case '16:9':
      return 'lg:aspect-video'
    default:
      return 'lg:aspect-[3/1]'
  }
}

const positionContent = (position: string) => {
  switch (position) {
    case 'start':
      return 'col-start-1 max-w-md'
    case 'end':
      return 'col-start-2 max-w-md'
    case 'full':
      return 'col-start-1 max-w-lg '
    default:
      return 'justify-center'
  }
}

const Hero = ({ image, headline, title, subtitle, button, background, contentPosition, color }) => {
  return (
    <section className={cx(customAspectRatio(image.customAspectRatio), styles.hero)}>
      <ImageElement objectPosition={'object-center-bottom'} image={image} priority={true} fill />
      <div className={styles.heroContainer}>
        <div
          className={cx(
            'lg:grid lg:gap-8',
            `${contentPosition === 'full' ? 'lg:grid-cols-1 py-4 md:py-16 lg:py-8 items-center' : 'lg:grid-cols-2'}`,
          )}
        >
          <div className={cx(positionContent(contentPosition), styles.heroContent)}>
            <div
              style={{ backgroundColor: color }}
              className={cx(
                `${
                  background === 'blurred'
                    ? 'rounded-xl p-8 backdrop-blur-sm'
                    : 'rounded-xl p-8 flex flex-col justify-center items-center'
                }`,
              )}
            >
              {headline && (
                <SafeLink href={button.url || ''}>
                  <span className={styles.label}>
                    {headline} <Arrow className="ml-1 h-5 w-5 text-white" width={20} height={20} aria-hidden="true" />
                  </span>
                </SafeLink>
              )}
              <div className={styles.mainTitles}>
                {title && (
                  <Text as="h1" className={styles.title}>
                    {title}
                  </Text>
                )}
                {subtitle && (
                  <Text as="h2" className={styles.subtitle}>
                    {subtitle}
                  </Text>
                )}
              </div>
              <div className="mt-6">
                <Button href={button?.href} type="anchor" variant={button?.variant} fullWidth>
                  {button?.title}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
