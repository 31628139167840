import { createElement, FC, ReactNode } from 'react'

type RenderAs = 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h6'

export interface TextProps {
  as?: RenderAs
  className?: string
  children?: ReactNode
}

const Text: FC<TextProps> = ({ as = 'p', children, className = '' }) => {
  return createElement(as, { className }, children)
}

export default Text
